import { MDXProvider } from '@mdx-js/react';
import React from 'react';
import Layout, { Location } from '../components/Layout';
import components from './remarkable';

export default function MDXLayout(props: {
  children: React.ReactNode;
  location: Location;
}) {
  const { children, location } = props;

  return (
    <Layout location={location}>
      <MDXProvider components={components as import('mdx/types').MDXComponents}>
        {children}
      </MDXProvider>
    </Layout>
  );
}
