/* eslint-disable prefer-template */
import AccountCircle from '@mui/icons-material/AccountCircle';
import Done from '@mui/icons-material/Done';
import Error from '@mui/icons-material/Error';
import Group from '@mui/icons-material/Group';
import SendOutlined from '@mui/icons-material/SendOutlined';
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  InputAdornment,
  LinearProgress,
  TextField,
  Typography,
} from '@mui/material';
import axios from 'axios';
import { graphql, StaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React, { Component } from 'react';
import DiscordIcon from '../../../assets/svg/DiscordIcon';
import { Item, Specialization } from '../../gw2components';
import Message from '../../Message';
import Link from '../../navigation/Link';

const MAX_STEPS = 6;

class ApplyForm extends Component {
  constructor(props) {
    super(props);
    // Set the initial input values

    this.state = {
      currentStep: 1, // Default is Step 1 up to step 8
      sendProgress: 0, // for handling loading icons on form submit buttom
      valid: 0, // if all fields are filled out that are required to submit a valid form.
      inputs: {
        /** ingame account name (name.XXXX) */
        accName: '',
        /** discord name (name#XXXX) */
        discordName: '',
        /** current main guild of applicant */
        mainGuild: '',
        /** applicant's api key to check whether requirements are fulfilled * */
        apiKey: '',
        /** voluntary: killproof.me link DEPRECATED (uses account name now) */
        kpLink: '', //
        /** confirmation of age and valid answers */
        requirements: '',
        /** list of applicant's power builds (for 98/99CM) */
        powerBuilds: '',
        /** list of applicant's condi builds (for 100CM) */
        condiBuilds: '',
        /** non-static only: logs of all cm bosses */
        soloLogs: '',
        /** non-static only: when the applicant can play with us  */
        soloTimes: '',
        /** non-static only: how the applicant usually fills their groups */
        soloFindMember: '',
        /** previous experience with fractals */
        experience: '',
        /** why they would like to join the guild */
        whyJoin: '',
      },
    };

    // Bind the submission to handleChange()
    this.handleChange = this.handleChange.bind(this);
  }

  // Use the submitted data to set the state
  handleChange = (e) => {
    const { value, name, type } = e.target;
    // console.log(value + ' : ' + type);

    const { inputs } = this.state;

    if (type === 'checkbox') {
      // requires special logic to save the input as a string list separated by comma
      const { checked } = e.target;
      if (checked) {
        const checks = inputs[name]
          .split(',')
          .filter((elem) => elem.length > 0);
        checks.push(value);
        inputs[name] = checks.join(',');
      } else {
        const checks = inputs[name].split(',');
        const filtered = checks.filter(
          (val) => val !== value && val.length > 0,
        );
        inputs[name] = filtered.length > 0 ? filtered.join(',') : '';
      }
    } else {
      // Other inputs require no special logic
      inputs[name] = value;
    }

    // Save the state again while respecting unchanged state variables.
    this.setState((prevState) => ({
      ...prevState,
      inputs,
    }));

    // check if the input is now valid (after a handleChange execution)
    this.validateInput();
    // console.log(this.state);
  };

  // Check if all required fields are not empty. Respects the static/non-static distinction
  validateInput = () => {
    let valid = 0;

    const {
      inputs: {
        accName,
        discordName,
        mainGuild,
        apiKey,
        requirements,
        powerBuilds,
        condiBuilds,
        soloLogs,
        soloTimes,
        soloFindMember,
        experience,
        whyJoin,
      },
    } = this.state;

    if (
      accName.length > 0 &&
      discordName.length > 0 &&
      mainGuild.length > 0 &&
      apiKey.length > 0 &&
      requirements.length > 0 &&
      powerBuilds.length > 0 &&
      condiBuilds.length > 0 &&
      experience.length > 0 &&
      whyJoin.length > 0 &&
      soloLogs.length > 0 &&
      soloTimes.length > 0 &&
      soloFindMember.length > 0
    ) {
      valid = 1;
    }
    this.setState((prevState) => ({
      ...prevState,
      valid,
    }));
  };

  doSubmit = async (e) => {
    this.setState((prevState) => ({
      ...prevState,
      sendProgress: 1,
    }));
    e.preventDefault();
    const { inputs } = this.state;
    const formData = Object.keys(inputs).map((key) => inputs[key]);

    // submit to cf worker
    axios
      .post(
        'https://discretize-serverless-apply.princeps.workers.dev/',
        formData,
      )
      .then((res) => {
        const json = res.data;
        if (json.status === 'SUCCESS') {
          this.setState((prevState) => ({
            ...prevState,
            sendProgress: 2,
            currentStep: 8,
          }));
        }
      })
      .catch((err) => {
        this.setState((prevState) => ({
          ...prevState,
          sendProgress: -1,
          error: err.message,
        }));
      });
  };

  next = () => {
    let { currentStep, inputs } = this.state;

    currentStep = currentStep >= MAX_STEPS - 1 ? MAX_STEPS : currentStep + 1;

    this.setState((prevState) => ({
      ...prevState,
      currentStep,
    }));
  };

  prev = () => {
    let { currentStep } = this.state;
    currentStep = currentStep <= 1 ? 1 : currentStep - 1;
    this.setState((prevState) => ({
      ...prevState,
      currentStep,
    }));
  };

  /*
   * the functions for our button
   */
  previousButton() {
    const { currentStep, sendProgress } = this.state;
    if (currentStep !== 1 && sendProgress !== 2 && sendProgress !== -1) {
      return (
        <Button variant="contained" color="primary" onClick={this.prev}>
          Back
        </Button>
      );
    }
    return null;
  }

  nextButton() {
    const { currentStep } = this.state;
    if (currentStep < MAX_STEPS) {
      return (
        <Button variant="contained" color="primary" onClick={this.next}>
          Next
        </Button>
      );
    }
    return null;
  }

  render() {
    const { currentStep, inputs, sendProgress, valid } = this.state;

    let sendContent = (
      <>
        <SendOutlined /> Send
      </>
    );
    if (sendProgress === 1) {
      sendContent = (
        <>
          <CircularProgress
            size="20px"
            color="primary"
            style={{ marginRight: '8px' }}
          />
          Sending
        </>
      );
    } else if (sendProgress === 2) {
      sendContent = (
        <>
          <Done /> Done
        </>
      );
    } else if (sendProgress === -1) {
      sendContent = (
        <>
          <Error /> Error
        </>
      );
    }

    return (
      <>
        <Grid container>
          <Grid item xs={12} md={6}>
            <StaticQuery
              query={graphql`
                {
                  picture: file(
                    sourceInstanceName: { eq: "images" }
                    relativePath: { eq: "background.jpg" }
                  ) {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              `}
              render={({ picture: { childImageSharp: image } }) => (
                <GatsbyImage
                  image={image.gatsbyImageData}
                  alt="Fractal Image"
                />
              )}
            />
            <LinearProgress
              variant="determinate"
              value={currentStep > MAX_STEPS ? 100 : (currentStep - 1) * 16.66}
              style={{ marginBottom: '16px' }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            style={{ paddingLeft: '16px', paddingTop: '8px' }}
          >
            <form target="_self" method="POST" onSubmit={this.doSubmit}>
              <Step1
                currentStep={currentStep}
                handleChange={this.handleChange}
                inputs={inputs}
              />
              <Step2
                currentStep={currentStep}
                handleChange={this.handleChange}
                inputs={inputs}
              />
              <Step3
                currentStep={currentStep}
                handleChange={this.handleChange}
                inputs={inputs}
              />
              <Step4
                currentStep={currentStep}
                handleChange={this.handleChange}
                inputs={inputs}
              />
              <Step5
                currentStep={currentStep}
                handleChange={this.handleChange}
                inputs={inputs}
              />
              <Step6
                currentStep={currentStep}
                handleChange={this.handleChange}
                inputs={inputs}
                valid={valid}
              />
            </form>
            <StepSuccess sendProgress={sendProgress} />
            <StepError sendProgress={sendProgress} inputs={inputs} />
          </Grid>
        </Grid>

        <Grid
          container
          justifyContent="space-between"
          style={{ marginTop: '8px' }}
        >
          <Grid item md={6} xs={12} />
          <Grid item md={1} xs={3}>
            {this.previousButton()}
          </Grid>
          <Grid item md xs={6} />
          <Grid item md={1} xs={3} style={{ textAlign: 'right' }}>
            {this.nextButton()}
            {currentStep === MAX_STEPS ? (
              <Button
                variant="contained"
                type="submit"
                color="primary"
                style={{ float: 'right' }}
                onClick={this.doSubmit}
                disabled={!valid}
              >
                {sendContent}
              </Button>
            ) : null}
          </Grid>
        </Grid>
      </>
    );
  }
}

function TextInput(props) {
  const { label, name, handleChange, helperText, value, ...rest } = props;
  return (
    <TextField
      style={{ width: '100%', marginTop: '0px', marginBottom: '16px' }}
      id="outlined-name"
      label={label}
      name={name}
      onChange={handleChange}
      margin="normal"
      variant="outlined"
      helperText={helperText}
      value={value}
      {...rest}
    />
  );
}

function CustomCheckbox(props) {
  const { name, handleChange, list, value, specialization } = props;

  const label = specialization ? (
    <Specialization text={value} name={specialization} disableLink />
  ) : (
    value
  );
  return (
    <FormControlLabel
      control={
        <Checkbox
          color="primary"
          onChange={handleChange}
          name={name}
          value={value}
          checked={list.includes(value)}
        />
      }
      label={label}
    />
  );
}

function Step1(props) {
  const { currentStep, handleChange, inputs } = props;
  if (currentStep !== 1) {
    return null;
  }
  return (
    <>
      <TextInput
        label="Account Name"
        name="accName"
        handleChange={handleChange}
        value={inputs.accName}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <AccountCircle />
            </InputAdornment>
          ),
        }}
      />
      <TextInput
        label="Discord Name"
        name="discordName"
        handleChange={handleChange}
        value={inputs.discordName}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <DiscordIcon style={{ width: '22px', marginLeft: '2px' }} />
            </InputAdornment>
          ),
        }}
      />
      <TextInput
        label="Main Guild"
        name="mainGuild"
        handleChange={handleChange}
        value={inputs.mainGuild}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Group />
            </InputAdornment>
          ),
        }}
      />
    </>
  );
}

function Step2(props) {
  const { currentStep, handleChange, inputs } = props;

  if (currentStep !== 2) {
    return null;
  }
  return (
    <>
      <TextInput
        label="API Key"
        name="apiKey"
        handleChange={handleChange}
        helperText={
          <>
            Create an API Key at{' '}
            <Link to="https://account.arena.net/applications">ArenaNet</Link>{' '}
            (required permissions: Account, characters, progression, builds,
            wallet and inventories).
            <br />
            The key is required to validate our requirements.
          </>
        }
        value={inputs.apiKey}
      />
      <TextInput
        label="killproof.me link"
        name="kpLink"
        handleChange={handleChange}
        helperText={
          <>
            Create an account at{' '}
            <Link to="https://killproof.me/">Killproof.me</Link> and share the
            link. The link is only required if you converted old KP to{' '}
            <Item id="94020" style={{ fontSize: '13px' }} />
          </>
        }
        value={inputs.kpLink}
      />
    </>
  );
}

function Step3(props) {
  const { currentStep, handleChange, inputs } = props;

  if (currentStep !== 3) {
    return null;
  }
  return (
    <>
      <FormControl component="fieldset">
        <FormLabel component="legend">Power builds</FormLabel>
        <FormGroup>
          <CustomCheckbox
            value="Power Renegade"
            specialization="Renegade"
            name="powerBuilds"
            list={inputs.powerBuilds}
            handleChange={handleChange}
          />
          <CustomCheckbox
            value="Power Scrapper"
            specialization="Scrapper"
            name="powerBuilds"
            list={inputs.powerBuilds}
            handleChange={handleChange}
          />
          <CustomCheckbox
            value="Power Soulbeast"
            name="powerBuilds"
            specialization="Soulbeast"
            list={inputs.powerBuilds}
            handleChange={handleChange}
          />
          <CustomCheckbox
            value="Power Dragonhunter"
            specialization="Dragonhunter"
            name="powerBuilds"
            list={inputs.powerBuilds}
            handleChange={handleChange}
          />
        </FormGroup>
        <FormHelperText>
          It is highly recommended to only apply with one role unless you feel
          confident to be able to provide high-end gameplay on more builds.
        </FormHelperText>
      </FormControl>
      <br />
    </>
  );
}

function Step4(props) {
  const { currentStep, handleChange, inputs } = props;

  if (currentStep !== 4) {
    return null;
  }
  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">Condi builds</FormLabel>
      <FormGroup>
        <CustomCheckbox
          value="Condi Harbinger"
          name="condiBuilds"
          specialization="Harbinger"
          list={inputs.condiBuilds}
          handleChange={handleChange}
        />
        <CustomCheckbox
          value="Condi Holosmith"
          name="condiBuilds"
          specialization="Holosmith"
          list={inputs.condiBuilds}
          handleChange={handleChange}
        />
        <CustomCheckbox
          value="Condi Willbender"
          name="condiBuilds"
          specialization="Willbender"
          list={inputs.condiBuilds}
          handleChange={handleChange}
        />
        <CustomCheckbox
          value="Condi Specter"
          name="condiBuilds"
          specialization="Specter"
          list={inputs.condiBuilds}
          handleChange={handleChange}
        />
      </FormGroup>
      <FormHelperText>
        It is highly recommended to only apply with one role unless you feel
        confident to be able to provide high-end gameplay on more builds.
      </FormHelperText>
    </FormControl>
  );
}

function Step5(props) {
  const { currentStep, handleChange, inputs } = props;

  if (currentStep !== 5) {
    return null;
  }
  return (
    <>
      <TextInput
        label="CM Encounter Logs"
        name="soloLogs"
        handleChange={handleChange}
        multiline
        value={inputs.soloLogs}
      />
      <TextInput
        label="When are you playing?"
        name="soloTimes"
        handleChange={handleChange}
        multiline
        value={inputs.soloTimes}
      />
      <FormControl component="fieldset" style={{ marginTop: '16px' }}>
        <FormLabel component="legend">
          How do you find members for your group?
        </FormLabel>
        <FormGroup>
          <Grid container>
            <Grid item md={6}>
              <CustomCheckbox
                value="Ingame LFG system"
                name="soloFindMember"
                list={inputs.soloFindMember}
                handleChange={handleChange}
              />
              <CustomCheckbox
                value="Main Guild"
                name="soloFindMember"
                list={inputs.soloFindMember}
                handleChange={handleChange}
              />
              <CustomCheckbox
                value="Static"
                name="soloFindMember"
                list={inputs.soloFindMember}
                handleChange={handleChange}
              />
            </Grid>
            <Grid item md={6}>
              <CustomCheckbox
                value="Friendlist"
                name="soloFindMember"
                list={inputs.soloFindMember}
                handleChange={handleChange}
              />
              <br />
              <CustomCheckbox
                value="Discord"
                name="soloFindMember"
                list={inputs.soloFindMember}
                handleChange={handleChange}
              />
            </Grid>
          </Grid>
        </FormGroup>
      </FormControl>
    </>
  );
}

function Step6(props) {
  const { currentStep, handleChange, inputs, valid } = props;

  if (currentStep !== 6) {
    return null;
  }
  return (
    <>
      <TextInput
        label="Previous fractal experience?"
        name="experience"
        handleChange={handleChange}
        multiline
        value={inputs.experience}
      />
      <TextInput
        label="Why do you want to join us?"
        name="whyJoin"
        handleChange={handleChange}
        multiline
        value={inputs.whyJoin}
      />
      <div style={{ marginTop: '16px', marginBottom: '16px' }}>
        <CustomCheckbox
          name="requirements"
          value="I have read the apply page and answered all questions truthfully. I am over 18 years old."
          list={inputs.requirements}
          handleChange={handleChange}
        />
      </div>
      {!valid && (
        <Message>
          Not all fields have been filled out. Please verify your application.
        </Message>
      )}
    </>
  );
}

function StepSuccess(props) {
  const { sendProgress } = props;
  if (sendProgress !== 2) {
    return null;
  }
  return (
    <>
      <Typography variant="h6">Success</Typography>
      <Typography variant="body1">
        We have received your application and will get back to you soon. The
        review of your application might take up to three days.
      </Typography>
    </>
  );
}

function StepError(props) {
  const { sendProgress } = props;
  if (sendProgress !== -1) {
    return null;
  }
  return (
    <>
      <Typography variant="h6">Error</Typography>
      <Typography variant="body1">
        An unexpected error occurred. Please contact Discretize for assistance.
      </Typography>
      <Button variant="contained" color="primary" component={Link} to="/apply">
        Go back
      </Button>
    </>
  );
}

export default ApplyForm;
